.body {
    font-family: 'Poppins', sans-serif;
    background-color: black;
  }

  /* Di dalam file CSS atau stylesheet Anda */
.custom-datepicker {
  width: 400px;
  height: 40px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-shadow: 4px 8px 8px rgba(0, 0, 0, 0.1);
  font-size: 16px;
}

  