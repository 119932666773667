/* global.css */

body {
    margin: -8px;
    background-color: #F0EEEE; /* Warna latar belakang global */
}

.RootLayout {
  padding-top: 8px; /* menggunakan 'px' untuk nilai margin-top */
  padding-left: 4px;
  margin: -8;
  height: 100vh;
  width: 'full';
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}




  