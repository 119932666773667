/* TabLayout.css */

.tab-layout-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 4px;
  }
  
  .tabs-container {
    display: flex;
    width: 400px;
    height: 40px;
    border-radius: 40px;
    overflow: hidden;
  }
  

  .tab {
    flex: 1;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    border-bottom: none;
    text-align: center;
    background-color: #004d4052;
    color: #004D40;
    user-select: none;
  }
  
  .tab.active {
    background-color: #004D40;
    font-weight: bold;
    color: white;
    user-select: none;
  }
  
  .tab-content {
    width: 100%;

  }
  